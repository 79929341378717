import type {NewsData, NewsFilters, NewsTag, PaginatedNewsData, RelatedArticleData} from '~/types/news'
import {CACHE_KEY_NEWS_LANDING_PAGE} from '~/cache'
import type {SuggestedArticle} from '~/types/types'
import {NEWS_STATUS_PUBLISHED, NEWS_STATUS_REJECTED} from '~/constants/news'
import type {TableHeaderSort} from '~/types/table'
import type {CSPotSession} from '~/types/user'
import {getCSpotSession} from '~/composables/auth/useAuth'
import {useFetchResource} from '~/composables/fetch/useFetchResource'
import {generateUrlParameterFromTagsAndLang} from '~/util/news'

const API_VERSION = 'v2'

const NEWS_ENDPOINT_ROOT = `news/${API_VERSION}`

export const useNewsData = async (query: any) => {
  return useFetchResource<PaginatedNewsData>({
    url: `${NEWS_ENDPOINT_ROOT}`,
    method: 'get',
    query,
    isPublic: true
  })
}

export const useNewsCountByStatus = async () => {
  return useFetchResource<NewsData>({
    url: `${NEWS_ENDPOINT_ROOT}/count-status`,
    method: 'get'
  })
}

export const useNewsDataBySlug = async (slug: string) => {
  const session: CSPotSession = getCSpotSession()

  return useFetchResource<NewsData>({
    url: () => `${NEWS_ENDPOINT_ROOT}/slug/${slug}`,
    method: 'get',
    isPublic: !(session && session.user)
  })
}

export const useGetAllNewsTags = async () => {
  return useFetchResource<NewsTag[]>({
    url: `${NEWS_ENDPOINT_ROOT}/tags`,
    method: 'get',
    isPublic: true
  })
}

export const useGetAllNewsSponsoredTags = async () => {
  return useFetchResource<NewsTag[]>({
    url: `${NEWS_ENDPOINT_ROOT}/sponsored-tags`,
    method: 'get',
    isPublic: true
  })
}

export const useGetPublicNewsList = async () => {
  const cachedData = useNuxtData(CACHE_KEY_NEWS_LANDING_PAGE)

  if (cachedData && cachedData.data && cachedData.data.value) {
    return cachedData
  }

  return useFetchResource<NewsData[]>({
    url: `${NEWS_ENDPOINT_ROOT}/public`,
    method: 'get',
    isPublic: true
  })
}

export const useCreateNewsTag = async (text: string, color: string, isSponsored: boolean) => {
  return useFetchResource<any>({
    url: `${NEWS_ENDPOINT_ROOT}/create-tag`,
    method: 'post',
    body: {text, color, isSponsored}
  })
}

export const useCreateNewsArticle = async (articleData: Object) => {
  return useFetchResource<any>({
    url: `${NEWS_ENDPOINT_ROOT}/create-article`,
    method: 'post',
    body: articleData
  })
}

export const useEditNewsArticle = async (id: string, articleData: Object) => {
  return useFetchResource<any>({
    url: `${NEWS_ENDPOINT_ROOT}/edit-article`,
    method: 'post',
    body: {id, ...articleData}
  })
}

export const useGetNumberNewsDraft = async () => {
  return useFetchResource<number>({
    url: `${NEWS_ENDPOINT_ROOT}/articles-draft`,
    method: 'get'
  })
}

export const useSuggestedArticles = async (size: number = 5, userId?: string) => {
  let url: string = `${NEWS_ENDPOINT_ROOT}/suggested-articles?size=${size}`
  if (userId) {
    url += `&owner=${userId}`
  }
  return useFetchResource<SuggestedArticle[]>({
    url: () => url,
    method: 'get'
  })
}

export const useGetAllNewsLanguages = async () => {
  return useFetchResource<any[]>({
    url: `${NEWS_ENDPOINT_ROOT}/languages`,
    method: 'get',
    isPublic: true
  })
}

export const useDeleteNews = async (id: string) => {
  return useFetchResource({
    url: () => `${NEWS_ENDPOINT_ROOT}/${id}`,
    method: 'delete'
  })
}

export const useLikeNews = async (id: string) => {
  return useFetchResource({
    url: () => `${NEWS_ENDPOINT_ROOT}/like?id=${id}`,
    method: 'post'
  })
}

export const useIsLikedNews = async (id: string) => {
  return useFetchResource<any>({
    url: () => `${NEWS_ENDPOINT_ROOT}/isLiked?id=${id}`,
    method: 'get'
  })
}

export const useNewsApproveArticle = async (id: string) => {
  return useFetchResource({
    url: `${NEWS_ENDPOINT_ROOT}/status`,
    method: 'post',
    body: {
      id,
      status: NEWS_STATUS_PUBLISHED
    }
  })
}

export const useNewsRejectArticle = async (id: string, reason: string, text: string) => {
  return useFetchResource({
    url: `${NEWS_ENDPOINT_ROOT}/status`,
    method: 'post',
    body: {
      id,
      status: NEWS_STATUS_REJECTED,
      reason,
      text
    }
  })
}

export const useDeleteArticleTag = async (id: string) => {
  return useFetchResource({
    url: () => `${NEWS_ENDPOINT_ROOT}/tag/${id}`,
    method: 'delete'
  })
}

export const useGetNewsFiltered = async (params: any, pageSize: number, pageNumber: number, sortBy: TableHeaderSort) => {
  let filter = `sortBy=${sortBy.param}&sortAsc=${sortBy.asc}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  if (params.text) {
    filter += `&text=${params.text}`
  }
  if (params.language) {
    filter += `&language=${params.language}`
  }
  if (params.tag) {
    filter += `&tag=${params.tag}`
  }
  if (params.sponsoredTag) {
    filter += `&sponsoredTag=${params.sponsoredTag}`
  }
  if (params.status) {
    filter += `&status=${params.status}`
  }
  return useFetchResource<NewsData[]>({
    url: () => `${NEWS_ENDPOINT_ROOT}/filtered?${filter}`,
    method: 'get'
  })
}

export const useShareNews = async (id: string, type: string) => {
  return useFetchResource<any>({
    url: `${NEWS_ENDPOINT_ROOT}/share`,
    method: 'post',
    body: {id, type}
  })
}

export const usePublishArticle = async (id: string) => {
  return useFetchResource({
    url: `${NEWS_ENDPOINT_ROOT}/publish`,
    method: 'post',
    body: {
      id
    }
  })
}

export const useGetNewsFilters = async (tag: string[], lang: string[]) => {
  const filterQuery = generateUrlParameterFromTagsAndLang(tag, lang)
  return useFetchResource<NewsFilters>({
    url: () => `${NEWS_ENDPOINT_ROOT}/filters${filterQuery ? '?' + filterQuery : ''}`,
    method: 'get'
  })
}

export const useGetNewsFilterSort = async () => {
  return useFetchResource<NewsFilters>({
    url: () => `${NEWS_ENDPOINT_ROOT}/filters/sort-by`,
    method: 'get'
  })
}

export const useGetRelatedNews = async (id: string) => {
  return useFetchResource<RelatedArticleData[]>({
    url: () => `${NEWS_ENDPOINT_ROOT}/related?id=${id}`,
    method: 'get'
  })
}
