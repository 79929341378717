export const generateUrlParameterFromTagsAndLang = (tag: string[], lang: string[]) => {
  let filterQuery: string | null = null
  if (tag && tag.length > 0) {
    filterQuery = `filter[tag]=[${'"' + tag.join('","') + '"'}]`
  }
  if (lang && lang.length > 0) {
    filterQuery = `${filterQuery ? `${filterQuery}&` : ''}filter[lang]=[${'"' + lang.join('","') + '"'}]`
  }
  return filterQuery
}
